import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { ProfileComponent } from './profile/profile.component';
import { SupportComponent } from './support/support.component';
import { ConsultantsComponent } from './consultants/consultants.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PhonePipe } from './shared/pipes/phone.pipe';
import { FormsModule } from '@angular/forms';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CollectionPersonalInfoNoticeComponent } from './collection-personal-info-notice/collection-personal-info-notice.component';
import { ContactUsAliComponent } from './contact-us-ali/contact-us-ali.component';
import { SharedModule } from '@app/shared/shared.module';

// Kendo Imports
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DepartmentService } from '@app/employee/employee/shared/services/department.service';
import { SuggestionComponent } from './suggestion/suggestion.component';

@NgModule({
	imports: [CommonModule, HomeRoutingModule, FlexLayoutModule, FormsModule, SharedModule, DropDownsModule, InputsModule],
	exports: [ContactUsAliComponent],
	declarations: [
		ProfileComponent,
		SupportComponent,
		ConsultantsComponent,
		PrivacyPolicyComponent,
		CollectionPersonalInfoNoticeComponent,
		PhonePipe,
		ContactUsAliComponent,
		SuggestionComponent,
	],
	providers: [DepartmentService],
})
export class HomeModule {}
