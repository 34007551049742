import { UserSettingsDto } from './../models/user-settings-dto';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { RoleDto } from '../models/role-dto';
import { UserInfoDto } from '../models/user-info-dto';
import { CredentailsDto } from '../models/credentials-dto';
import { EmailType } from '../models/email-type';
import { BaseApiClient } from './base-api-client.service';
import { LogService } from './log.service';
import { Environment } from '@env/environment';
import { UntypedFormGroup } from '@angular/forms';
import { LoadingIndicatorService } from './loading-indicator.service';
import { IsLoggedInUserAuthorizedToViewPersonResultDto } from '../models/is-loggedin-user-authorized-to-view-person-dto';
import { PersonAuthorityDto } from '../models/person-authority-dto';
import { UserRegistrationInfoDto } from '@app/employee/employee/shared/models/userRegistrationInfoDto';
import { UserStateDto } from '../models/user-state-dto';
import { SsoConfigurationDto } from '../../clients/shared/models/ssoConfigurationDto';
import { ServiceToNameDto } from '@app/clients/shared/models/serviceToNameDto';
import { SsoUpdateDto } from '@app/clients/shared/models/ssoUpdateDto';

export interface ISecurityService {
	url: string;
	getAvailableHrRoles(primaryLocationServiceToId: string): Observable<RoleDto[]>;
	getAvailableEhsRoles(userId: string, clientId?: string): Observable<RoleDto[]>;
	getCredentials(id: string): Observable<CredentailsDto>;
	getMyCredentials(): Observable<CredentailsDto>;
	getMyUserInfo(): Observable<UserInfoDto>;
	getServiceToApplicationFeatures(primaryLocationServiceToId: string): Observable<any>;
	getAllEhsRoles(includeKpa: boolean): Observable<RoleDto[]>;
	getUserState(): Observable<UserStateDto>;
	logout(): Observable<Object>;
	//getSsoConfiguration(clientId: string): Observable<any>;
	saveSsoConfiguration(ssoConfig: SsoConfigurationDto): Observable<any>;
	updateSsoConfiguration(clientId: string, config: SsoConfigurationDto): Observable<any>;

	isLoggedInUserAuthorizedToViewPersonInHrm(personUserId: string): Observable<IsLoggedInUserAuthorizedToViewPersonResultDto>;
}

const httpOptions = {
	headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
	providedIn: 'root',
})
export class SecurityService extends BaseApiClient implements ISecurityService {
	public url: string; // URL to web api

	constructor(
		private readonly http: HttpClient,
		private readonly log: LogService,
		private readonly loadingIndicatorService: LoadingIndicatorService,
		environment: Environment
	) {
		super();
		this.url = environment.services['security'];
	}

	getUserState(): Observable<UserStateDto> {
		const url = `${this.url}Credentials/UserState`;

		return this.http.get<UserStateDto>(url).pipe(
			tap((): void => this.log.logInformation(`fetched My UserState`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	getAvailableHrRoles(primaryLocationServiceToId: string): Observable<RoleDto[]> {
		const url = `${this.url}roles/GetAvailableHrRoles/${primaryLocationServiceToId}`;

		this.loadingIndicatorService.startLoading();
		return this.http.get<RoleDto[]>(url).pipe(
			tap((): void => {
				this.log.logInformation(`Got Hr roles`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError((err) =>
				err.status == 404
					? of([]).pipe(tap((): void => this.loadingIndicatorService.stopLoading()))
					: this.createErrorResponseHandler(this.log, undefined, (_) => {
							this.loadingIndicatorService.stopLoading();
						})(err)
			)
		);
	}

	logout(): Observable<Object> {
		const url = `${this.url}Authentication/Logout`;
		return this.http.post(url, undefined, httpOptions).pipe(
			tap((): void => {
				this.log.logInformation(`Logged out user`);
			}),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	getAvailableEhsRoles(userId: string, clientId?: string): Observable<RoleDto[]> {
		let url = `${this.url}roles/EhsRoles?ssoUserId=${userId}&clientId=${clientId}`;
		if (!clientId) {
			url = `${this.url}roles/EhsRoles?ssoUserId=${userId}`;
		}
		this.loadingIndicatorService.startLoading();
		return this.http.get<RoleDto[]>(url).pipe(
			tap((): void => {
				this.log.logInformation(`Got Ehs roles`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError((err) =>
				err.status == 404
					? of([]).pipe(tap((): void => this.loadingIndicatorService.stopLoading()))
					: this.createErrorResponseHandler(this.log, undefined, (_) => {
							this.loadingIndicatorService.stopLoading();
						})(err)
			)
		);
	}

	getAllEhsRoles(includeKpa: boolean): Observable<RoleDto[]> {
		const url = `${this.url}roles/GetAllEhsRoles/${includeKpa}`;

		this.loadingIndicatorService.startLoading();
		return this.http.get<RoleDto[]>(url).pipe(
			tap((): void => {
				this.log.logInformation(`Got Ehs roles`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError((err) =>
				err.status == 404
					? of([]).pipe(tap((): void => this.loadingIndicatorService.stopLoading()))
					: this.createErrorResponseHandler(this.log, undefined, (_) => {
							this.loadingIndicatorService.stopLoading();
						})(err)
			)
		);
	}

	isPhoneNumberUnique(phoneNumber: string): Observable<any> {
		if (!phoneNumber || phoneNumber.length === 0) {
			return of(true);
		}
		const url = `${this.url}Credentials/IsPhoneNumberUnique/${phoneNumber}?isDuplicateCheck=True`;
		return this.http.get(url).pipe(
			tap((): void => this.log.logInformation(`fetched isPhoneNumberUnique with phone number`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	getCredentials(id: string): Observable<CredentailsDto> {
		const url = `${this.url}Credentials/${id}`;

		return this.http.get<CredentailsDto>(url).pipe(
			tap((): void => this.log.logInformation(`fetched Credentials id=${id}`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	getMyCredentials(): Observable<CredentailsDto> {
		const url = `${this.url}Credentials/My`;

		return this.http.get<CredentailsDto>(url).pipe(
			tap((): void => this.log.logInformation(`fetched My Credentials`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	updateMyCredentials(entity: CredentailsDto): Observable<any> {
		const url = `${this.url}Credentials/My`;
		return this.http.put<CredentailsDto>(url, entity, httpOptions).pipe(
			tap((): void => this.log.logInformation(`updated UserProfileData`)),
			catchError(this.createErrorResponseHandler(this.log))
		);
	}

	getMyUserInfo(): Observable<UserInfoDto> {
		const url = `${this.url}UserInfo/My`;

		this.loadingIndicatorService.startLoading();
		return this.http.get<UserInfoDto>(url).pipe(
			tap((): void => {
				this.log.logInformation(`fetched GetCurrentUserInfo`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	getMyUserSettings(): Observable<UserSettingsDto> {
		const url = `${this.url}UserSettings/My`;

		this.loadingIndicatorService.startLoading();
		return this.http.get<UserSettingsDto>(url).pipe(
			tap((): void => {
				this.log.logInformation(`fetched GetCurrentUserSettings`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	getMyPersonAuthority(): Observable<PersonAuthorityDto> {
		const url = `${this.url}Authorities/Hrm/My`;

		this.loadingIndicatorService.startLoading();
		return this.http.get<PersonAuthorityDto>(url).pipe(
			tap((): void => {
				this.log.logInformation(`fetched My Authorities`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	sendRegistrationEmail(ssoUserId: string, emailType: EmailType): Observable<any> {
		const url = `${this.url}Credentials/SendUserRegistrationCode`;
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
		};

		let entity = { ssoUserId: ssoUserId, emailType: EmailType[emailType] };

		this.loadingIndicatorService.startLoading();
		return this.http.post(url, entity, httpOptions).pipe(
			tap(() => {
				this.log.logInformation(`registration email sent`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	sendPasswordReset(ssoUserId: string, emailType: EmailType): Observable<any> {
		const url = `${this.url}Credentials/SendPasswordReset`;
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
		};

		let entity = { ssoUserId: ssoUserId, emailType: EmailType[emailType] };

		this.loadingIndicatorService.startLoading();
		return this.http.post(url, entity, httpOptions).pipe(
			tap(() => {
				this.log.logInformation(`registration email sent`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	getUnregisteredEmployees(clientId: string): Observable<UserRegistrationInfoDto[]> {
		const url = `${this.url}Credentials/${clientId}/GetUnregisteredEmployees`;

		this.loadingIndicatorService.startLoading();
		return this.http.get<UserRegistrationInfoDto[]>(url).pipe(
			tap(() => {
				this.log.logInformation(`get unregistered employees`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	getServiceToApplicationFeatures(primaryLocationServiceToId: string): Observable<any> {
		const url = `${this.url}ApplicationFeatures/GetServiceToApplicationFeatures/${primaryLocationServiceToId}`;

		this.loadingIndicatorService.startLoading();
		return this.http.get<any>(url).pipe(
			tap((): void => {
				this.log.logInformation(`Got application features`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	isLoggedInUserAuthorizedToViewPersonInHrm(personUserId: string): Observable<IsLoggedInUserAuthorizedToViewPersonResultDto> {
		const url = `${this.url}hrm/users/${personUserId}/IsLoggedInUserAuthorizedToViewPerson`;

		this.loadingIndicatorService.startLoading();
		return this.http.post<any>(url, undefined).pipe(
			tap((): void => {
				this.log.logInformation(`Got IsLoggedInUserAuthorizedToViewPerson result`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	validatePasswordResetCode(formGroup: UntypedFormGroup): Observable<any> {
		const url = `${this.url}Credentials/PasswordResetCodeValidation`;
		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
		};

		const entity = formGroup.value;

		if (entity) {
			return this.http.post(url, entity, httpOptions).pipe(
				tap(() => this.log.logInformation('Validated code')),
				catchError(this.createErrorResponseHandler(this.log, formGroup))
			);
		}
	}
	/**
	 * Get client SSO configuration
	 * @param clientId
	 */
	getSsoConfiguration(clientId: string): Observable<any> {
		const errorHandler = this.createErrorResponseHandler(this.log);
		if (clientId) {
			const url = `${this.url}SsoConfiguration/${clientId}`;

			return this.http.get(url, httpOptions).pipe(
				tap((ssoConfig: SsoConfigurationDto) => this.log.logInformation(`retrieved sso configuration for id=${clientId}`)),
				catchError(errorHandler)
			);
		}
	}

	saveSsoConfiguration(ssoConfigForm: SsoConfigurationDto): Observable<any> {
		const errorHandler = this.createErrorResponseHandler(this.log);
		if (ssoConfigForm) {
			const url = `${this.url}SsoConfiguration`;

			return this.http.post(url, ssoConfigForm, httpOptions).pipe(
				tap((id) => this.log.logInformation(`saved sso configuration`)),
				catchError(errorHandler)
			);
		}
	}
	updateSsoConfiguration(clientId: string, ssoConfigForm: SsoConfigurationDto): Observable<any> {
		const errorHandler = this.createErrorResponseHandler(this.log);
		if (ssoConfigForm) {
			const url = `${this.url}SsoConfiguration/${clientId}`;

			return this.http.put(url, ssoConfigForm, httpOptions).pipe(
				tap((id) => this.log.logInformation(`saved sso configuration`)),
				catchError(errorHandler)
			);
		}
	}

	getRegistrationLocations() {
		const url = `${this.url}Credentials/RegistrationLocations`;

		this.loadingIndicatorService.startLoading();
		return this.http.get<ServiceToNameDto[]>(url).pipe(
			tap(() => {
				this.log.logInformation(`get registeration locations`);
				this.loadingIndicatorService.stopLoading();
			}),
			catchError(this.createErrorResponseHandler(this.log, undefined, (_) => this.loadingIndicatorService.stopLoading()))
		);
	}

	updateSsoLocation(ssoUpdateDto: SsoUpdateDto): Observable<any> {
		const errorHandler = this.createErrorResponseHandler(this.log);
		if (ssoUpdateDto) {
			const url = `${this.url}Credentials/UpdateSsoUserLocation`;
			this.loadingIndicatorService.startLoading();
			return this.http.post<any>(url, ssoUpdateDto, httpOptions).pipe(
				tap((id) => {
					this.log.logInformation(`updated sso details`);
					this.loadingIndicatorService.stopLoading();
				}),
				catchError(errorHandler)
			);
		}
	}
}
