import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthComponent } from './components/auth/auth.component';
import { AppErrorHandler } from './services/app-error-handler';
import { AuthGuardService } from './services/auth-guard.service';
import { SecurityService } from './services/security.service';
import { LandingComponent } from '@app/core/components/landing/landing.component';
import { LogService } from './services/log.service';
import { LOCAL_STORAGE, SESSION_STORAGE } from './services/storage';
import { AuthInterceptor } from './services/auth-interceptor';
import { DateHttpInterceptor } from './services/date-http-interceptor';
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';
import { Environment } from '@env/environment';
import { ImpersonateGuardService } from './services/impersonate-guard.service';
import { RouterModule } from '@angular/router';
import { NoCacheInterceptor } from './services/nocache-interceptor';
import { RequestCacheService } from './services/request-cache.service';
import { AccountManagementAuthGuardService } from './services/account-management-auth-guard.service';
import { LoadingIndicatorService } from './services/loading-indicator.service';
import { ErrorComponent } from './components/error/error.component';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { GLBAAuthGuardService } from './services/glba-auth-guard.service';
import { ASFAuthGuardService } from './services/asf-auth-guard.service';

@NgModule({
	declarations: [AuthComponent, LandingComponent, ErrorComponent],
	imports: [HttpClientModule, RouterModule, ButtonModule],
	exports: [AuthComponent, HttpClientModule],
	providers: [
		AuthGuardService,
		AccountManagementAuthGuardService,
		LogService,
		SecurityService,
		Environment,
		CanDeactivateGuard,
		ImpersonateGuardService,
		RequestCacheService,
		LoadingIndicatorService,
		GLBAAuthGuardService,
		ASFAuthGuardService,
		{ provide: ErrorHandler, useClass: AppErrorHandler },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: NoCacheInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: DateHttpInterceptor, multi: true },
		{ provide: LOCAL_STORAGE, useValue: localStorage },
		{ provide: SESSION_STORAGE, useValue: sessionStorage },
	],
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		if (parentModule) throw new Error('CoreModule is already loaded. Do not import it below the level of AppModule.');
	}
}
