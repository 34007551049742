import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionsLayout } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-dynamic-dialog',
  templateUrl: './dynamic-dialog.component.html',
  styleUrls: ['./dynamic-dialog.component.css']
})
export class DynamicDialogComponent implements OnInit {

  actionsLayout: ActionsLayout = 'end';
	constructor() {}

	ngOnInit() {}

	@Output() confirm = new EventEmitter<void>();
	@Output() close = new EventEmitter<void>();

  @Input() dialogPrompt: string;
  @Input() closePrompt: string;
  @Input() confirmPrompt: string;
  @Input() titlePrompt: string;

	onConfirm() {
		this.confirm.emit();
	}

	onClose() {
		this.close.emit();
	}

}
